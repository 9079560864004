import React, { Component } from 'react';
import './style.css';

class Cookies extends Component {
  render() {
    return (
      <>
        <br /><br /><br /><h1>Cookies....</h1>
        <p>...</p>
      </>
    )
  }
}

export default Cookies;