import { store }  from '../../store/index';
import React, { useState } from 'react';
//IMMAGINI
//import imgMascotte from '../assets/Mascotte_tempo.png';
import axios from 'axios';

function Login() {
 
    const handleCouponRedention = () => {
        inviaLogin();
    }

    const inviaLogin = async () => {
        window.loading(true);
        let apiTarget = "";
      //gestico la chiamata con il coupon oppure con le credenziali
        if(formData.coupon !== '') {
           apiTarget = window.apiUrl+'/loginFromCoupon';   
        } else {
           apiTarget = window.apiUrl+'/login';
        }

        try {
            const result = await axios.post(apiTarget, formData);
                // La chiamata è andata a buon fine, puoi gestire la risposta qui
            console.log(result.data);
            if ('message' in result.data) {
                if('data' in result.data) {
                    if('token' in result.data.data) {
                        store.dispatch({ type: 'SET_TOKEN', payload: result.data.data.token });
                        store.dispatch({ type: 'SET_USER', payload: result.data.data.name });
                        store.dispatch({ type: 'SET_EMAIL', payload: result.data.data.email });
                        console.log("mutationData 3 %o", store.getState());
                        localStorage.setItem('token', result.data.data.token);
                        localStorage.setItem('user', result.data.data.name);
                        localStorage.setItem('email', result.data.data.email);
                        localStorage.setItem('coupon', result.data.data.coupon);
                        localStorage.setItem('eta', result.data.data.eta);
                        localStorage.setItem('id_utente', result.data.data.id);
                        window.location.replace("/team");
                    }
                }
            }
        } catch (error) {
             window.loading(false);
            // Gestisci l'errore in modo appropriato
            // estraggo il response
            let message = 'Errore generico per il login';
            if(error.response) {
                message = error.response.data.message;
            }
            
            console.error('Error:', message);
            window.openModal1("Errore di autenticazione", message)
        } finally{
            window.loading(false);
            console.log("finally, loading false");
        }
    };

   
    let message = '';   

    /*  GESTISCO IL FORM    */
    const [formData, setFormData] = useState({
            email: '',
            password: '',
            coupon: ''
        });

    const handleInputChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    };

    const [isActive, setActive] = useState(false);
    const ToggleClass = () => {
        setActive(!isActive); 
        scrollToBottom();
    };

    const scrollToBottom = () => {
      
        window.scrollTo(0, (document.body.scrollHeight+500));
    };

    return (
       <>
            <h1  onClick={ToggleClass} className="titoloCentrale">LOGIN</h1>
            <div className={!isActive ? "active loginBox homeChoise" : "hidden loginBox homeChoise"}>
                {message}
               <form onSubmit={handleSubmit}>
                    <div className="container mt-4">
                      <div className='row paddigTop40'>
                          <div className="col-md formCoupon"> 
                            <h4 className='center'>Login con coupon</h4>
                            <label>Coupon: </label><input type="text" name="coupon" value={formData.coupon} onChange={handleInputChange} />
                            <div className='center'><button onClick={handleCouponRedention}>Login</button></div> 
                          </div>
                        </div>
                        <div className='paddigTop40 center'>Oppure</div>
                        <div className="row paddigTop40">
                            <div className="col-md formCoupon"> 
                              <h4 className='center'>Login credenziali</h4> 
                              <label>Email: </label><input type="email" name="email" value={formData.email} onChange={handleInputChange} />
                              <br />
                              <label>Password: </label><input type="password" name="password" value={formData.password} onChange={handleInputChange} />
                              <div className='center'><button onClick={handleCouponRedention}>Login</button></div> 
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
} 
export default Login;