import React, { Component } from 'react';
import './style.css';
import imgIndietro from '../../assets/frecciaSx.png';
import imgMascotte from '../../assets/Mascotte Stampa.png';


class About extends Component {
  render() {
    return (
       <>
          <div className='textBox homeChoise'>  
            <ul>
                <li><span className='evidenzaInElenco textMuseoCeramica'><b>MUSEO DELLA CERAMICA</b></span> Al suo interno non custodisce solo collezioni, ma anche installazioni multimediali e percorsi multisensoriali per raccontare un manufatto, le ceramiche, un mestiere, quello del ceramista e le materie prime, terra-acqua-pigmenti.</li>
                <li><span className='evidenzaInElenco textMuseoStamoa'><b>MUSEO CIVICO DELLA STAMPA</b></span> Un percorso immersivo legato al libro, alla stampa e al luogo che lo accoglie. Dall’invenzione dei caratteri mobili alla digitalizzazione, non solo macchinari per comprendere la rivoluzione della comunicazione moderna.</li>
                <li><span className='evidenzaInElenco textTorreBelvedere'><b>TORRE DEL BELVEDERE</b></span>Simbolo della città e punto di osservazione privilegiato sul vasto panorama circostante. Un Parco del Tempo.</li>
                <li><span className='evidenzaInElenco textChiesaMissione'><b>CHIESA DELLA MISSIONE</b></span>Un’esperienza multimediale e immersiva tra architetture, personaggi e inganni visivi del barocco piemontese.</li>
            </ul>
            <p>Tutto è stato realizzato grazie al contributo della Fondazione CRC nell’ambito del bando FuoriOrario 2022.</p>
          </div>
      </>
    )
  }
}

export default About;