import React from "react";
import coppa from "../../assets/coppa.png";
import imgArte from "../../assets/Mascotte Arte.png";
import imgTempo from "../../assets/Mascotte tempo.png";
import imgStampa from "../../assets/Mascotte Stampa.png";
import imgCeramica from "../../assets/Mascotte Ceramica.png";
import mappa from "../../assets/mappa.png";
import './style.css';

function MyComponent() {
  return (
    <div>
      <a href={mapUrl} target="_blank" rel="noopener noreferrer">
        Vai a Google Maps
      </a>
    </div>
  );
}

function openMapBox(img) {
  document.getElementById("mapBoxImg").src = img;
  document.getElementById("mapBox").style.display = "block";
}

var latitude = 37.7749; // Latitudine desiderata
var longitude = -122.4194; // Longitudine desiderata
var mapUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
var cont1 = 0;
var urlFF = "";

var immagini = [];
immagini["arte"] = imgArte;
immagini["tempo"] = imgTempo;
immagini["stampa"] = imgStampa;
immagini["ceramica"] = imgCeramica;

var urlBase = "https://giochi123piazza.mgdemo.it/img/";

// array di immagini
var imgMap = [];
imgMap["6-8"] = [];

imgMap["6-8"][2] = urlBase + "27-24e20_CERAMICA-DUOMO_focus.jpg";
imgMap["6-8"][3] = urlBase + "27-24e20_CERAMICA-DUOMO_focus.jpg";
imgMap["6-8"][4] = urlBase + "24e20-12e11_DUOMO-TORRE_focus.jpg";
imgMap["6-8"][5] = urlBase + "24e20-12e11_DUOMO-TORRE_focus.jpg";
imgMap["6-8"][6] = urlBase + "12e11-9_TORRE-GOVERNATORE_focus.jpg";
imgMap["6-8"][7] = urlBase + "9-28e1_GOVERNATORE-FUNICOLARE_focus.jpg";
imgMap["6-8"][8] = urlBase + "9-28e1_GOVERNATORE-FUNICOLARE_focus.jpg";
imgMap["6-8"][9] = urlBase + "28e1-10_FUNICOLARE-GIUSTIZIA_focus.jpg";
imgMap["6-8"][10] = urlBase + "10-17_GIUSTIZIA-MISSIONE_focus.jpg";
imgMap["6-8"][11] = urlBase + "17-4_MISSIONE-GIOVANNI_focus.jpg";
imgMap["6-8"][12] = urlBase + "4-25_GIOVANNI-CITTADELLA_focus.jpg";
imgMap["6-8"][13] = urlBase + "25-19_CITTADELLA-MISERICORDIA_focus.jpg";
imgMap["6-8"][14] = urlBase + "19-26_MISERICORDIA-MOMIGLIANO_focus.jpg";
imgMap["6-8"][15] = urlBase + "26-2e3_MOMIGLIANO-STAMPA_focus.jpg";
imgMap["6-8"][16] = urlBase + "26-2e3_MOMIGLIANO-STAMPA_focus.jpg";

imgMap["9-12"] = [];
imgMap["9-12"][3] = urlBase + "30e32-14_CERAMICA-BRESSANI_focus.jpg";
imgMap["9-12"][4] = urlBase + "14-13_BRESSANI-TORRE_focus.jpg";
imgMap["9-12"][5] = urlBase + "21-23_VESCOVADO-SANTA CHIARA_focus.jpg";
imgMap["9-12"][6] = urlBase + "23-15_SANTA CHIARA-GOVERNATORE_focus.jpg";
imgMap["9-12"][7] = urlBase + "15-16_GOVERNATORE-GIUSTIZIA_focus.jpg";
imgMap["9-12"][8] = urlBase + "13-21_TORRE-VESCOVADO_focus.jpg";
imgMap["9-12"][9] = urlBase + "16-18e22_GIUSTIZIA-MISSIONE_focus.jpg";
imgMap["9-12"][10] = urlBase + "16-18e22_GIUSTIZIA-MISSIONE_focus.jpg";
imgMap["9-12"][11] = urlBase + "18e22-33_MISSIONE-FAUZONE_focus.jpg";
imgMap["9-12"][12] = urlBase + "33-7_FAUZONE-GIOVANNI_focus.jpg";
imgMap["9-12"][13] = urlBase + "7-8_GIOVANNI-CITTADELLA_focus.jpg";
imgMap["9-12"][14] = urlBase + "8-31_CITTADELLA-MOMIGLIANO_focus.jpg";
imgMap["9-12"][15] = urlBase + "31-5e6_MOMIGLIANO-STAMPA_focus.jpg";
imgMap["9-12"][16] = urlBase + "31-5e6_MOMIGLIANO-STAMPA_focus.jpg";

// se manca local storage "tema" lo imposto a "TUTTE LE TAPPE"
if (!localStorage.getItem("tema")) {
  localStorage.setItem("tema", "TUTTE LE TAPPE");
}

const ListaGiochi = (
  elencoGiochiObj,
  open,
  close,
  change,
  g_id,
  cercaPrePost
) => (
  console.log(
    "Elenco giochi %o e tema %o",
    elencoGiochiObj,
    localStorage.getItem("tema")
  ),
  (cont1 = 0),
  (
    <div>
      <div className="textBox">
        <h1 className="titoloCentraleGiochiElenco">
          {localStorage.getItem("tema").toUpperCase()}
        </h1>
        <div className="centerImg">
          <img
            src={immagini[localStorage.getItem("tema")]}
            alt=""
            className="mascotteCentrale"
          />
        </div>
        <ul className="giochi">
          {elencoGiochiObj.map(
            (item) => (
              (latitude = item.lat),
              (longitude = item.long),
              (mapUrl = `https://www.google.com/maps?q=${latitude},${longitude}`),
              cont1++,
              (urlFF = imgMap[item.eta][cont1]),
              console.log("item %o - %o - %o", item, cont1, urlFF),
              (
                <li
                  key={item.id}
                  className={item.ended_at ? "giochi superato" : "giochi"}
                >
                  {item.ended_at && (
                    <div className="ended_at">
                      <img src={coppa} alt="Coppa" className="imgCoppa" />{" "}
                      <button>{item.tappa.toUpperCase()}</button>
                    </div>
                  )}
                  {!item.ended_at && (
                    <button
                      onClick={() => {
                        change(
                          item.url,
                          item.id_hash,
                          item.popup,
                          item.id_gioco
                        );
                        cercaPrePost(item.id_gioco);
                        window.blockWindowBox();
                        window.loading(true, "ListaGiochi");
                      }}
                    >
                      {item.tappa.toUpperCase()}
                    </button>
                  )}

                  {urlFF && (
                    <a href={urlFF} target="_blank" rel="noopener noreferrer">
                      <img src={mappa} className="mapIcon floatRight" />
                    </a>
                  )}
                </li>
              )
            )
          )}
        </ul>
      </div>
    </div>
  )
);

export default ListaGiochi;
