import React from 'react';
import './style.css';
import axios from 'axios';
import { store } from '../../store/index';
import { useState, useEffect } from 'react';


function Coupons () {

    const [coupons, setCoupons] = useState([]);
     useEffect(() => {
            const bearer_token = `Bearer ${store.getState().savedToken}`;
            const config = {
                headers: {
                    Authorization: bearer_token
                }
            };

            let formData = {email: localStorage.getItem('email')};
            axios.post(window.apiUrl+'/coupons', formData, config)
                .then(function (response) {
                    console.log(response.data);
                    if ('message' in response.data) {
                      setCoupons(response.data.data);
                    } else {
                        
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    window.openModal1("Errore", "Occorre effettuare il login");
                });
     }, []);


     const [count, setCount] = useState(0);
     // setta in sessione il nome del giocatore selesionato
      const setCoupon = (coupon) => {
        console.log(coupon);
        localStorage.setItem('coupon', coupon);

        window.location.href = '/team';
      }

    return (
      <div className="homeContainer">
        <h1 className='titoloCentrale'>Coupons</h1>
          <div className="textBox homeChoise">
            <p>Elenco dei coupon</p>
              <ul>
        {coupons.map((coupon) => (
          <li key={coupon.id}>
            <button disabled={coupon.scaduto === true ? true : false} className={localStorage.getItem('coupon') === coupon.coupon ? 'selected' : ''} onClick={() => setCoupon(coupon.coupon)}>{coupon.coupon} {coupon.scaduto === true ? "Scaduto" : ""}
              {coupon.scadenza_coupon_tra > 0  ? (<div className='piccolo'>Scade tra {coupon.scadenza_coupon_tra} ore</div>) : ""}
            </button>
          </li>
        ))}
      </ul>
    </div>
      <div className="row dati">
          <ul>
            <li>
              <strong>Utente:</strong> {localStorage.getItem('user')}              
            </li>
            <li>
              <strong>Email:</strong> {localStorage.getItem('email')}
            </li>


          </ul>
      </div>
      </div>
    )
}

export default Coupons;