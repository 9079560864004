import React from "react";
import axios from "axios";
import { store } from "../../store/index";
import { useState, useEffect } from "react";
import mascotte from "../../assets/Mascotte Ceramica.png";
import { RitiraAttestato } from "./RitiraAttestato";

function Statistiche() {
  const [statisticheArr, setStatistiche] = useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  useEffect(() => {
    const bearer_token = `Bearer ${store.getState().savedToken}`;
    const config = {
      headers: {
        Authorization: bearer_token,
      },
    };

    let formData = { coupon: localStorage.getItem("coupon") };
    axios
      .post(window.apiUrl + "/giochiRiassunto", formData, config)
      .then(function (response) {
        console.log(response.data.data);
        setStatistiche(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
        window.openModal1(
          "Errore",
          "Si è verificato un errore, riprova più tardi"
        );
      });
  }, []);

  if (isLoading) return "Loading...";
  if (error) return "Error!";

  return (
    <div className="statistiche">
      <img className="mascotte" src={mascotte}></img>
      <h1>Statistiche</h1>
      <div>
        {statisticheArr.map((statistiche) => (
          <div key={statistiche.id}>
            <h2>
              Team: <b>{statistiche.nome}</b>
            </h2>
            {statistiche.oreTotali ? (
              <ul>
                <li>
                  Vinte:{" "}
                  <b>
                    {statistiche.vinte} su {statistiche.giocate}
                  </b>
                </li>
                {statistiche.vinte > 0 && (
                  <>
                    <li>
                      Totale tempo giocato: <b>{statistiche.oreTotali}</b>
                    </li>
                    <li>
                      Ora di riscatto del coupon:{" "}
                      <b>{statistiche.oraInizioPrimoGioco}</b>
                    </li>
                    <li>
                      Ora di fine dell'ultimo gioco:{" "}
                      <b>{statistiche.oraFineUltimoGioco}</b>
                    </li>
                  </>
                )}
                {statistiche.vinte == statistiche.giocate && (
                  <li>
                    <RitiraAttestato id={statistiche.id_team} />
                  </li>
                )}
              </ul>
            ) : (
              <ul>
                <li>Non ci sono statistiche disponibili!</li>
              </ul>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
export default Statistiche;
