import React, { Component } from "react";
import "./style.css";

class Cookies extends Component {
  render() {
    return (
      <>
        <br />
        <br />
        <br /> <br />
        <br />
        <br />
        <h1>Informativa sui Cookies</h1>
        <p>
          Un “cookie” è un file di testo creato dal programma di navigazione per
          registrare alcune informazioni utili all'utilizzo sul sito.
        </p>
        <p>
          I Titolari dei dati ricevono e registrano informazioni provenienti dai
          siti che visitate che possono includere anche dati personali. <br />I
          cookie vengono talvolta utilizzati per raccogliere queste
          informazioni, che possono includere fra le altre anche:
          <br />- (i) indirizzo IP;
          <br />- (ii) unique cookie identifier, informazioni sui cookie e
          informazioni circa il fatto che il dispositivo disponga di software
          per accedere a determinate funzionalità;
          <br />- (iii) unique device identifier e tipo di dispositivo;
          <br />- (iv) dominio, tipo di browser e lingua,
          <br />- (v) sistema operativo e impostazioni di sistema;
          <br />- (vi) paese e fuso orario;
          <br />- (vii) siti web precedentemente visitati;
          <br />- (viii) informazioni sulla vostra interazione con i nostri
          Siti, come i click effettuati, gli acquisti e le preferenze indicate;
          <br />- (ix) tempi di accesso e URL di riferimento.
        </p>
        <p>
          I cookie sono inviati da un server web (che è il computer sul quale è
          in esecuzione il sito web visitato) al browser dell'utente (Internet
          Explorer, Mozilla Firefox, Google Chrome, ecc.)
        </p>
        <p>I cookie sono essenzialmente di 3 tipi.</p>
        <p>
          <b>Cookie di sessione o funzionali</b>: sono memorizzati
          temporaneamente nel computer durante la navigazione di un sito, ad
          esempio per tenere traccia della lingua scelta. Vengono cancellati dal
          computer alla chiusura del browser.
        </p>
        <p>
          <b>Cookie Prestazionali</b>: questi cookie consentono di migliorare la
          funzionalità dei nostri Siti monitorandone l'utilizzo. In alcuni casi,
          questi cookie migliorano la velocità di elaborazione delle richieste e
          consentono di memorizzare le preferenze del sito selezionato.
          Rifiutare questi cookie può portare a delle indicazioni poco
          specifiche e ad un funzionamento più lento del sito.
        </p>
        <p>
          <b>Cookie di terze parti</b>: spesso utilizzati per monitotare e
          gestire la navigazione tra diversi domini per finalità di marketing e
          vendita.
        </p>
        <p>
          Tutti i cookies che utilizziamo su questa web app <b>sono strettamente
          funzionali all'utilizzo e alla navigazione e dipendono strettamente
          dalla app.</b> Non raccogliamo, ad esempio, informazioni sui siti web
          precedentemente visitati.
          <b> Non facciamo installare cookies di terze parti</b> che possono
          tracciare e condividere con altri siti le vostre informazioni.
        </p>
        <p>
          Non dipendono da questa App i cookies che scarichere utilizzando il
          questionario di Google Form o la mappa di Google Map.
        </p>
        <p>
          Per eliminare i cookie dal proprio browser, è possibile farlo
          attraverso il menu del browser stesso. Per avere maggiori informazioni
          su come fare, è possibile consultare il sito del fornitore del proprio
          browser.
        </p>
        <p>
          Per maggiori informazioni sui cookies, è possibile consultare il sito
          del Garante della Privacy.
        </p>
        <p>
          Per un riepilogo completo e aggiornato di tutte le terze parti che
          accedono al web browser, è consigliabile installare un web browser
          plug-in creato appositamente. Si può anche scegliere che il computer
          invii un avviso ogni volta che viene inviato un cookie, oppure si può
          scegliere di disattivare tutti i cookie. Lo si può fare nelle
          impostazioni del browser su ciascun browser e dispositivo che viene
          utilizzato. Ogni browser è in parte diverso, quindi si dovrà
          consultare il menu guida del browser per conoscere il modo corretto di
          modificare i cookie. Se si disattivassero i cookie, si potrebbe non
          avere accesso a molte funzionalità che rendono i nostri Siti più
          efficienti e alcuni dei nostri servizi non funzioneranno
          correttamente.
        </p>
        <br />
        <br /> <br />
        <br />
        <br />
        <p></p>
      </>
    );
  }
}

export default Cookies;
