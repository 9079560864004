import React, { Component } from 'react';
import './style.css';
import imgMascotte from '../../assets/Mascotte Ceramica.png';
import imgIndietro from '../../assets/frecciaSx.png';
import cos_e_testo from './cos_e_testo';

class About extends Component {
  render() {

    const linkHome = () => {
      window.location.replace("/"); 
    }

    return (
      <div className='homeContainer'>
        <img src={imgMascotte} alt="Mascotte" className='imgLogo' /> 
        <h1 className="titoloCentrale">Che cosa è</h1>
        <div className='textBox homeChoise'> 
          <cos_e_testo />
       </div>
         <img src={imgIndietro}   onClick={linkHome}  alt="" className='frecceNavigazione' />
      </div>
    )
  }
}

export default About;