import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import { store }  from '../index';



const couponApi = createApi({   
    reducerPath: 'coupons',
    baseQuery: fetchBaseQuery({
       baseUrl: window.apiUrl, 
        prepareHeaders: (headers, { getState }) => {
            const token =  store.getState().savedToken; 
            if (token) {
            // include token in req header
                headers.set('authorization', `Bearer ${token}`)  
                return headers
            }
        }
    }),
    endpoints(builder) {
        return {
            couponRedention: builder.mutation({
                query: (couponObj) => {
                    return {
                        url: '/registraCoupon',
                        method: 'POST',
                        body: couponObj
                    }
                },
            }),
            getCoupon: builder.query({
                couponObj: {},
                query: (couponObj) => '/giochi',
                method: 'GET',
                params: {}
            }),
            getGioco: builder.query({
                couponObj: {},
                query: (couponObj) => '/giochi',
                method: 'GET',
                params: {}
            }),
            getGiochi: builder.mutation({       
                query: (giochiQueryObj) => {
                    return {
                        url: '/giochi',
                        method: 'POST',
                        body: giochiQueryObj
                    }
                },
            }),
            login: builder.mutation({       
                query: (loginQueryObj) => {
                    return {
                        url: '/login',
                        method: 'POST',
                        body: loginQueryObj
                    }
                },
            }),
        }
    }
});
export const {  
                useGetCouponQuery, 
                useGetGiocoQuery,
                useCouponRedentionMutation, 
                useGetGiochiMutation, 
                useLoginMutation
            } = couponApi;
export default couponApi;