import React, { Component } from 'react';
import './style.css';
import imgLogo from '../../assets/LOGO123Piazza.png';
import imgMascotte from '../../assets/Mascotte_tempo.png';


class About extends Component {
  render() {

    const linkHome = () => {
      window.location.replace("/"); 
    }

    return (
      <>
         <img src={imgLogo} alt="Mascotte" className='imgLogo' /> 
        <h2>Che cosa è</h2>
        <p>Un urban game per le vie di Mondovì Piazza, la parte più alta e storica della città!
Attraverso enigmi e rompicapi, percorrerai vie e piazze, girando intorno a palazzi e chiese, per scoprire qualcosa in più dei principali punti di interesse storici, artistici e architettonici cittadini. </p>

<p>4 tematiche, 4 sfaccettature della stessa mascotte, 4 punti cardine di questo tour, ti accompagneranno nell'esplorazione:</p>
  <ul>
    <li>la <span className='textCeramica'>ceramica</span>, una delle attività di artigianato più peculiari di Mondovì;</li>
    <li>il <span className='textTempo'>tempo</span>, a simboleggiare il grande orologio visibile da ogni dove;</li>
    <li>la <span className='textStampa'>stampa</span> perché qui, più che altrove, ha rivestito un ruolo basilare per la conoscenza;</li>
    <li> l’<span className='textArte'>arte</span> che ci circonda e può portarti in inganno all’infinito.</li>
  </ul>
  <h2>Come Funziona</h2>
  <ul>
      <li>Compila tutti i campi: ci servono per garantirti la dovuta privacy e per far sì che nessun altro possa utilizzare il tuo coupon.
</li>
      <li>Una volta effettuato il pagamento, riceverai il coupon. Inseriscilo nel campo apposito e poi conservalo: ti permetterà di accedere al gioco per le prossime 48 ore! E non solo: presentandolo nelle biglietterie dei 4 musei, potrai entrare a tariffa ridotta.
</li>
      <li>Effettuato l’accesso, scegli la difficoltà con cui affrontare le tappe che ti porteranno a girare per Mondovì Piazza. La più adeguata rispetto alla tua età (6-8 anni oppure 9-12 anni).
</li>
      <li> Inizia a giocare! Le tappe non sono consequenziali, ma ti consiglieremo sempre quelle più vicine a te tra cui scegliere. Infatti a seguito di ogni tappa, ti verrà richiesto quale direzione vorrai prendere: puoi ritornare sui tuoi passi per tentare di risolvere nuovamente l’enigma o procedere verso una nuova meta;  l’importante è affrontarle tutte nel minor tempo possibile!
</li>
    
  </ul>
            <img src={imgMascotte}   onClick={linkHome}  alt="Mascotte" className='imgMascotte' /> 
            <h1  onClick={linkHome} className="titoloHome">Gioca!</h1> 
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
  <h2>Chi coinvolge</h2>
  <ul>
      <li><span className='evidenzaInElenco textMuseoCeramica'>MUSEO DELLA CERAMICA</span> Al suo interno non custodisce solo collezioni, ma anche installazioni multimediali e percorsi multisensoriali per raccontare un manufatto, le ceramiche, un mestiere, quello del ceramista e le materie prime, terra-acqua-pigmenti.</li>
      <li><span className='evidenzaInElenco textMuseoStamoa'>MUSEO CIVICO DELLA STAMPA</span> Un percorso immersivo legato al libro, alla stampa e al luogo che lo accoglie. Dall’invenzione dei caratteri mobili alla digitalizzazione, non solo macchinari per comprendere la rivoluzione della comunicazione moderna.</li>
      <li><span className='evidenzaInElenco textTorreBelvedere'>TORRE DEL BELVEDERE</span>Simbolo della città e punto di osservazione privilegiato sul vasto panorama circostante. Un Parco del Tempo.</li>
      <li><span className='evidenzaInElenco textChiesaMissione'>CHIESA DELLA MISSIONE</span>Un’esperienza multimediale e immersiva tra architetture, personaggi e inganni visivi del barocco piemontese.</li>
  </ul>

  <p>Tutto è stato realizzato grazie al contributo della Fondazione CRC nell’ambito del bando FuoriOrario 2022.</p>
      </>
    )
  }
}

export default About;