import React, { Component } from 'react';
import './style.css';
import imgMascotte from '../../assets/Mascotte_tempo.png';
import imgIndietro from '../../assets/frecciaSx.png';

class Come_funziona extends Component {
  render() {

    const linkHome = () => {
      window.location.replace("/"); 
    }

    return (
      <div className='homeContainer'>
         <img src={imgMascotte} alt="Mascotte" className='imgLogo' /> 
          <h1  className="titoloCentrale">Come Funziona</h1>
          <div className='textBox homeChoise'>
            <ul>
            <li><b>COMPILA TUTTI I CAMPI:</b> ci servono per garantirti la dovuta privacy e per far sì che nessun altro possa utilizzare il tuo coupon.</li>
            <li><b>RICEVERAI IL COUPON, </b> una volta effettuato il pagamento riceverai il coupon. Inseriscilo nel campo apposito e poi conservalo: ti permetterà di accedere al gioco per le prossime 48 ore! E non solo: presentandolo nelle biglietterie dei 4 musei, potrai entrare a tariffa ridotta.</li>
            <li><b>EFFETTUA L'ACCESSO, </b>scegli fino a due squadre e per ciascuna la difficoltà con cui affrontare le tappe che ti porteranno a girare per Mondovì Piazza. Opta per la difficoltà più adeguata rispetto alla tua età (facile per bambini di 6-8 anni oppure medio per bambini di 9-12 anni).</li>
            <li><b>INIZIA A GIOCARE! </b> Le tappe non sono consequenziali, ma usa la mappa per scegliere sempre quelle più vicine a te. L’importante è affrontarle tutte nel minor tempo possibile!</li>
            </ul>
            <img src={imgMascotte}   onClick={linkHome}  alt="Mascotte" className='imgMascotte' /> 
            <h1  onClick={linkHome} className="titoloHome">Gioca!</h1> 
          </div>
          <img src={imgIndietro}   onClick={linkHome}  alt="" className='frecceNavigazione' />
       </div>
    )
  }
}
export default Come_funziona;