import React, { Component } from "react";
import "./style.css";

class NoteLegali extends Component {
  render() {
    return (
      <><br /><br /><br />
        <h1>Note Legali</h1>
        <p>
          Un URBAN GAME per le vie di Mondovì Piazza, la parte più alta e
          storica della città!
        </p>
        <p>
          <b>Indirizzo:</b>
          <br />
          Museo della Ceramica di Mondovì
          <br />
          Palazzo Fauzone di Germagnano
          <br />
          Piazza Maggiore 1
          <br />
          12084 Mondovì (CN)
          <br />
          <b>Mail:</b>
          segreteria@museoceramicamondovi.it
          <br />
          <b>Ufficio turistico del Comune di Mondovì</b>: 0174 330358
        </p>
 
      </>
    );
  }
}

export default NoteLegali;
