import React, { Component } from 'react';
import './style.css';

import TextCos_e from '../About/cos_e_testo';
import TextChi_coinvolge from '../About/chi_coinvolge_testo';
import TextCome_funziona from '../About/come_funziona_testo';



//IMMAGINI
import backgroundImage from '../../assets/rifiloBasso.png';
import accedi from '../../assets/accedi.png';
import chi_coinvolge from '../../assets/chi_coinvolge.png';
import come_funziona from '../../assets/come_funziona.png';
import cos_e from '../../assets/cos_e.png';

import { useState } from 'react';





export default function Home() {
  const [isOpenCos_e, setIsOpen] = useState(false);
  const [isOpenCome_funziona, setIsOpenCome_funziona] = useState(false);
  const [isOpenChi_coinvolge, setIsOpenChi_coinvolge] = useState(false);
  

  function toggle(cosa) {
    setIsOpen((isOpenCos_e) => false);
    setIsOpenCome_funziona((isOpenCome_funziona) => false);
    setIsOpenChi_coinvolge((isOpenChi_coinvolge) => false);


    switch (cosa)
    {
      case "cos_e":
        setIsOpen((isOpenCos_e) => !isOpenCos_e);
        break;
      case "come_funziona":
        setIsOpenCome_funziona((isOpenCome_funziona) => !isOpenCome_funziona);
        break;
      case "chi_coinvolge": 
        setIsOpenChi_coinvolge((isOpenChi_coinvolge) => !isOpenChi_coinvolge);
        break;
      default: 
          alert('Default case');
    }
    
  }

  return (
    <>
    <h1 className='h1Home' style={{ backgroundImage: `url(${backgroundImage})` }}>PRONTI AD ESPLORARE ?</h1>
    <div className='homeContainer'>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>

    <a onClick={() => toggle('cos_e')}>
      <img src={cos_e}  alt="Mascotte Ceramica" className="sceltaCentrale" />
      {isOpenCos_e && <TextCos_e />}
    </a>
    <a onClick={() => toggle('come_funziona')}>
      <img src={come_funziona}  alt="Mascotte Ceramica" className="sceltaCentrale" />
      {isOpenCome_funziona && <TextCome_funziona />}
    </a>
    <a onClick={()=> toggle('chi_coinvolge')}>
      <img src={chi_coinvolge}  alt="Mascotte Ceramica" className="sceltaCentrale" />
      {isOpenChi_coinvolge && <TextChi_coinvolge />}
    </a>
    {sessionStorage.getItem('token') && (
      <a href="/riscatta">
        <img src={accedi} alt="Mascotte Ceramica" className="sceltaCentrale" />
      </a>
    )}
    {!sessionStorage.getItem('token') && (
      <a href="/login">
        <img src={accedi} alt="Mascotte Ceramica" className="sceltaCentrale" />
      </a>
    )} 
    </div>
    <div className="homeFooter">
      <p>© 2024 - 123 Piazza - Tutti i diritti riservati</p>
      <p><a href='/privacy'>Privacy</a> | <a href='/cookies'>Cookies</a> | <a href='/notelegali'>Note Legali</a></p>
    </div>
    </>
  )
}