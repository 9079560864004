import React, { Component } from "react";
import "./style.css";

class Author extends Component {
  render() {
    return (
      <div id="privacyPage">
        <br />
        <br />
        <br />
        <h1>Privacy</h1>
        Gent.mi,
        <br />
        il Regolamento (UE) 2016/679 stabilisce che il soggetto interessato
        debba essere preventivamente informato in merito all’utilizzo dei dati
        che lo riguardano e che il trattamento di dati personali è ammesso solo
        con il consenso espresso del soggetto interessato salvo i casi previsti
        dalla legge. Questa Privacy Policy si applica esclusivamente a tutti i
        dati raccolti attraverso il Sito web
        https://123piazza.museoceramicamondovi.it/ . <br />
        <br />
        <b>Titolare del trattamento</b>
        <br />
        I dati personali saranno trattati congiuntamente dai seguenti
        co-titolari:
        <br />
        <ul>
          <li>
            Fondazione Museo Della Ceramica "Vecchia Mondovì", Piazza Maggiore
            1, 12084 Mondovì (CN), Tel. 339 7161997, Mail
            segreteria@museoceramicamondovi.it;
          </li>
          <li>
            Museo Civico Della Stampa, piazza d’Armi 2/E, 12084 Mondovì (CN),
            Tel. 334.7059307, Mail
          </li>
          <li>
            Torre Del Belvedere, Giardini del Belvedere, 12084 Mondovì (CN),
            Tel. 0174.330358, Mail
          </li>
        </ul>
        <b>Modalità di Trattamento dei Dati Personali</b>
        <br />I Dati Personali fornirti o acquistati saranno oggetto di
        Trattamento improntato ai principi di correttezza, liceità, trasparenza
        e di tutela della riservatezza ai sensi delle vigenti normative. <br />
        Il Titolare tratta i Dati Personali degli Utenti adottando opportune
        misure di sicurezza volte ad impedire l’accesso, la divulgazione, la
        modifica o la distruzione non autorizzata dei Dati Personali. <br />
        Il Trattamento viene effettuato mediante strumenti informatici e/o
        telematici, con modalità organizzative e con logiche strettamente
        correlate alle Finalità indicate.
        <br />
        Fra i Dati Personali raccolti da questo Sito web, in modo autonomo o
        tramite terze parti, ci sono: Cookie, Dati di utilizzo, E-mail e Nome
        della famiglia o gruppo (opzionale), password per la registrazione sul
        sito.
        <br />
        <br />
        <b>Finalità del trattamento</b>
        <br />I dati personali richiesti come obbligatori sono trattati
        nell’ambito della normale attività dell’azienda Fondazione Museo Della
        Ceramica "vecchia Mondovì" per le seguenti finalità:
        <br />
        <ul>
          <li>
            Erogazione del servizio richiesto: I dati personali sono necessari
            per erogare il servizio richiesto dall'utente, ossia l'acquisto di
            un coupon per partecipare all'urban game "1, 2, 3...Piazza!".
          </li>
          <li>Emissione della ricevuta di pagamento.</li>
          <li>Comunicazione del coupon.</li>
          <li>Elaborazioni statistiche in forma anonima.</li>
          <li>
            Geolocalizzazione dei partecipanti: Durante l'utilizzo dell'App,
            vengono registrati i punti di interesse visitati dai giocatori per
            analizzare quali sono i luoghi di maggior interesse.
          </li>
          <li>
            I dati personali saranno facoltativamente trattati a seguito del suo
            consenso espresso nell’ambito dell’attività dell’azienda Fondazione
            Museo Della Ceramica "vecchia Mondovì” per finalità di marketing,
            che includono:
          </li>
          <li>
            Marketing online: inviare newsletter, campagne promozionali e invio
            tramite mail di elaborazioni statistiche in forma anonima.
          </li>
        </ul>
        <br />
        <b>Tipologie di dati trattati</b>
        <br />
        Per le finalità sopra indicate, trattiamo le seguenti categorie di dati
        personali:
        <br />
        <ul>
          <li>
            Dati di contatto: Nome della famiglia o gruppo (opzionale),
            indirizzo E-mail.
          </li>
          <li>
            Dati di pagamento: Informazioni necessarie per effettuare il
            pagamento e rilasciare la ricevuta.
          </li>
          <li>
            Dati di utilizzo: Informazioni relative all'uso dell'App e del
            coupon.
          </li>
          <li>
            Dati di geolocalizzazione: Posizione geografica dei giocatori
            durante l'utilizzo dell'App.
          </li>
        </ul>
        <br />
        <b>
          Destinatari o categorie di destinatari ai quali i dati personali
          possono essere comunicati
        </b>
        <br />
        Società o persone esterne che svolgono attività strettamente connesse e
        strumentali alla gestione del rapporto commerciale quali:
        <br />
        <ul>
          <li>
            Personale autorizzato della Fondazione: Solo il personale
            autorizzato della Fondazione, che ha necessità di conoscere tali
            dati per le finalità sopra descritte, può accedere ai dati
            personali.
          </li>
          <li>
            Fornitori di servizi esterni: I fornitori di servizi che supportano
            la Fondazione nella gestione dell'App, nell'elaborazione dei
            pagamenti, nella manutenzione dei sistemi IT e nelle analisi
            statistiche. Questi fornitori sono vincolati da obblighi
            contrattuali a mantenere la riservatezza e la sicurezza dei dati
            personali e a utilizzarli esclusivamente per le finalità specificate
            dalla Fondazione.
          </li>
          <li>
            Società esterne, consulenti e liberi professionisti, singoli e
            associati, per l’erogazione dei servizi richiesti.
          </li>
          <li>
            Autorità competenti: In caso di obbligo legale o per adempiere a
            richieste delle autorità giudiziarie o amministrative competenti.
          </li>
          <li>
            Si precisa che MUSEO CIVICO DELLA STAMPA e TORRE DEL BELVEDERE in
            quanto società collegate Fondazione Museo Della Ceramica "vecchia
            Mondovì", sono anch'esse destinatarie dei dati personali
            dell'interessato per le finalità sopra indicate.
          </li>
        </ul>
        <br />
        <b>Periodo di conservazione</b>
        <br />I dati personali trattati saranno conservati per il periodo di
        durata del contratto e, successivamente, per il tempo in cui Fondazione
        Museo Della Ceramica "vecchia Mondovì" sia soggetta a obblighi di
        conservazione per finalità fiscali o per altre finalità previste da
        norme di legge o regolamento.
        <br />
        In ogni caso, il periodo di conservazione dei dati personali sarà
        comunque conforme ai principi di minimizzazione e limitazione della
        conservazione stabiliti dall'art. 5 del GDPR. <br />
        Ciò significa che i dati personali saranno conservati per il tempo
        minimo necessario al raggiungimento delle finalità per le quali sono
        stati raccolti e successivamente trattati, ovvero per un periodo di
        tempo superiore se previsto da specifiche disposizioni di legge o
        regolamento.
        <br />I dati personali trattati per le finalità di marketing online
        saranno conservati fino al momento della revoca del consenso da parte
        dell'interessato.
        <br />
        L'interessato potrà revocare il proprio consenso in qualsiasi momento,
        inviando una E-mail a segreteria@museoceramicamondovi.it,
        info@museostampamondovi.it e iatmondovi@visitcuneese.it o chiamando i
        numeri di telefono 339.7161997; 334.7059307; 0174.330358.
        <br />
        <br />
        <b>Diritti dell’interessato</b>
        <br />
        L'interessato ha diritto di ottenere dal Titolare del trattamento la
        conferma che sia o meno in corso un trattamento di dati personali che lo
        riguardano e in tal caso ha il diritto di:
        <br />
        <ul>
          <li>
            ottenere l’accesso ai dati personali, chiedere la rettifica o la
            cancellazione dei dati personali o la limitazione del trattamento
            dei dati personali che lo riguardano o di opporsi al loro
            trattamento;
          </li>
          <li>
            di ricevere in un formato strutturato, di uso comune e leggibile da
            dispositivo automatico i dati personali che lo riguardano e ha il
            diritto di trasmettere tali dati a un altro titolare del trattamento
            (portabilità dei dati);
          </li>
          <li>
            essere informato dell’esistenza di un processo decisionale
            automatizzato, compresa la profilazione;
          </li>
          <li>
            se espresso revocare il consenso in qualsiasi momento senza
            pregiudicare la liceità del trattamento basata sul consenso prestato
            prima della revoca;
          </li>
          <li>proporre reclamo all’autorità di controllo;</li>
        </ul>
        <br />
        <b>Protezione dei dati personali</b>
        <br />
        <ul>
          <li>
            Crittografia e sicurezza: utilizziamo una varietà di misure tecniche
            ed organizzative di sicurezza, ivi inclusi strumenti di crittografia
            e di autenticazione, per mantenere la sicurezza dei dati personali.
            I dati personali sono contenuti in reti protette e sono accessibili
            solo da un numero limitato di persone che hanno diritti speciali di
            accesso a questi sistemi.
          </li>
          <li>
            Trasferimenti internazionali di dati: i dati personali che
            raccogliamo o produciamo (elaboriamo) nel contesto dei nostri Siti
            saranno archiviati esclusivamente UE.
          </li>
        </ul>
        <br />
        <b>DOMANDE E FEEDBACKS</b>
        <br />
        Accogliamo con favore domande, commenti e dubbi sulla nostra Policy. Se
        si desidera fornire un feedback o se si hanno domande o dubbi ovvero si
        desideri esercitare i propri diritti relativi ai dati personali,
        preghiamo di contattare i riferimenti riportati nella sezione Titolare
        del trattamento
        <br />
        Natura dei dati e conseguenza della mancata comunicazione Senza il
        trattamento e la comunicazione dei dati di natura obbligatoria per le
        descritte finalità, non si potrà fornire all’interessato i servizi e/o i
        prodotti richiesti, in tutto o in parte.
        <br /> <br />
        <hr />
      </div>
    );
  }
}

export default Author;
