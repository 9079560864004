import React, { Component } from "react";

import { Provider } from "react-redux";
import { store } from "./store";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Home from "./components/Home";
import Header from "./components/Header";
import Footer from "./components/Footer"; //<Footer/>
import About from "./components/About";
import Contact from "./components/Contact";
import NotFound from "./components/NotFound";
import Author from "./components/Author";
import Giochi from "./components/Giochi";
import MyIframe from "./components/Gioco";
import Team from "./components/Team";
import Coupons from "./components/Coupons";
import Riscatta from "./components/Accesso/riscattaCoupon";
import Login from "./components/Accesso/login";
import Cos_e from "./components/About/cos_e";
import Come_funziona from "./components/About/come_funziona";
import Chi_coinvolge from "./components/About/chi_coinvolge";
import AreaRiservata from "./components/AreaRiservata";
import Cookies from "./components/Cookies";
import Privacy from "./components/Privacy";
import NoteLegali from "./components/NoteLegali";

import "./App.css";

const savedLocalToken = localStorage.getItem("token");
const savedLocalUser = localStorage.getItem("user");
const savedLocalEmail = localStorage.getItem("email");

//impostto alcune variabili globali di sessione alla partenza di cotrollo
localStorage.setItem("youWin", 0);
localStorage.setItem("youWinOld", 0);
localStorage.setItem("closeGameWindowCommand", 0);

localStorage.setItem("tour", 0);

//const [gameBoxUrl, changeGameBoxUrl] = React.useState("http://localhost:3005/?g_id=2747b7c718564ba5f066f0523b03e17f6a496b06851333d2d59ab6d863225848")
//var gameBoxUrl = "http://localhost:3005/?g_id=2747b7c718564ba5f066f0523b03e17f6a496b06851333d2d59ab6d863225848";
var showGameBoxVar = true;

if (savedLocalToken) {
  store.dispatch({ type: "SET_TOKEN", payload: savedLocalToken });
  store.dispatch({ type: "SET_USER", payload: savedLocalUser });
  store.dispatch({ type: "SET_EMAIL", payload: savedLocalEmail });
  //
}

const hideGameBox = () => {
  const modal = document.getElementById("gameBox");
  if (document.getElementById("gameBox") === null) {
    console.log("hideGameBox is null");
    return;
  }
  modal.style.display = "none";
};
const showGameBox = () => {
  console.log("showGameBox");
  const modal = document.getElementById("gameBox");
  if (modal === null) {
    console.log("showGameBox is null");
    return;
  }
  modal.style.display = "block";
};

const reloadGame = () => {
  console.log("reloadGame");
  showGameBoxVar = true;
};

class App extends Component {
  constructor(props) {
    super(props);
    this.handleLoginClick = this.handleLoginClick.bind(this);
    this.handleLogoutClick = this.handleLogoutClick.bind(this);
    this.changeUrlGameBox = this.changeUrlGameBox.bind(this);

    this.state = { showGameBoxVar: true };
    //this.state = {gameBoxUrl: "http://localhost:3005****"};
    this.state = { gameBoxUrl: "https://giochi123piazza.mgdemo.it" };
    this.state = {
      g_id: "2747b7c718564ba5f066f0523b03e17f6a496b06851333d2d59ab6d863225848****",
    };
  }

  handleLoginClick() {
    this.setState({ showGameBoxVar: true });
  }

  handleLogoutClick() {
    this.setState({ showGameBoxVar: false });
  }

  changeUrlGameBox(url, g_id, popupText, giocoId) {
    this.setState({ gameBoxUrl: url });
    this.setState({ g_id: g_id });
    this.setState({ popupText: popupText });
    this.setState({ giocoId: giocoId });
    showGameBox();
    reloadGame();
    this.handleLoginClick();
    window.loading(false, "changeUrlGameBox");
  }

  render() {
    return (
      <BrowserRouter>
        <Header />
        <main className="flex-shrink-0">
          <div className="container">
            <Provider store={store}>
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route
                  path="/giochi"
                  element={
                    <Giochi
                      open={this.handleLoginClick}
                      close={this.handleLogoutClick}
                      change={this.changeUrlGameBox}
                    />
                  }
                />
                <Route path="/riscatta" element={<Riscatta />} />
                <Route path="/login" element={<Login />} />
                <Route path="/team" element={<Team />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/author" element={<Author />} />
                <Route path="/coupons" element={<Coupons />} />
                <Route path="/cos_e" element={<Cos_e />} />
                <Route path="/come_funziona" element={<Come_funziona />} />
                <Route path="/chi_coinvolge" element={<Chi_coinvolge />} />
                <Route path="/areariservata" element={<AreaRiservata />} />
                <Route path="/cookies" element={<Cookies />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/notelegali" element={<NoteLegali />} />

                <Route path="*" element={<NotFound />} />
              </Routes>
              {this.state.showGameBoxVar ? (
                <MyIframe
                  g_id={this.state.g_id}
                  url2Open={this.state.gameBoxUrl + "&g_id=" + this.state.g_id}
                  shown={showGameBoxVar}
                  hide={hideGameBox}
                  popupText={this.state.popupText}
                  giocoId={this.state.giocoId}
                />
              ) : null}
            </Provider>
          </div>
        </main>
      </BrowserRouter>
    );
  }
}

export default App;
