import React, { useCallback, useRef, useEffect } from "react";
import {
  APIProvider,
  Map,
  AdvancedMarker,
  Pin,
  mapTypeId,
} from "@vis.gl/react-google-maps";
import { useState } from "react";

const containerStyle = {
  width: "100%",
  height: "500px",
  borderRadius: "25px",
  border: "3px solid #f8aa00",
  overflow: "hidden",
};

const center = {
  lat: 44.38773673,
  lng: 7.8290236,
};

//key1 è un parametro che serve per evitare che la mappa venga ricaricata inutilmente, da implementare in futuro

const MapComponent = ({ points, onMarkerClick, key1 }) => {
  const [userLat, setUserLat] = useState();
  const [userLong, setUserLong] = useState();
  /*
  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setUserLat(position.coords.latitude);
      setUserLong(position.coords.longitude);
      console.log(userLat, userLong);
    });
  }, []);
  alert(userLat, userLong);
*/
  return (
    <>
      <APIProvider apiKey={"AIzaSyDHiT4m1qGWf8DmrVtBRk9YMMpLAffPyjo"}>
        <Map
          style={containerStyle}
          defaultCenter={{ lat: 44.38773673, lng: 7.8290236 }}
          defaultZoom={15}
          gestureHandling={"greedy"}
          disableDefaultUI={true}
          mapTypeId={"hybrid"}
          mapId={"546546546" + key1}
        >
          {points.map((point) => (
            <AdvancedMarker
              key={point.id_gioco}
              id={point.id_gioco}
              position={{ lat: point.lat, lng: point.long }}
              onClick={() => onMarkerClick(point)}
            >
              <Pin
                background={point.backgroundColor}
                borderColor={"#f8aa00"}
                glyphColor={"#c64068"}
              />
            </AdvancedMarker>
          ))}
        </Map>
      </APIProvider>
    </>
  );
};

export default MapComponent;
