import React, { Component } from 'react';
import './style.css';
import RiscattaCoupon from '../Coupon';
//import AcquistaCoupon from '../Acquista'; //<AcquistaCoupon /> //da implementare
import Login from '../Login';
//IMMAGINI
import mascotteCeramica from '../../assets/Mascotte Ceramica.png';


class Home extends Component {
  render() {
    return (
      <>
      <div className='homeContainer'>
        <img src={mascotteCeramica}  alt="Mascotte Ceramica" className="mascotteCentrale" />
        <br/>
        <RiscattaCoupon />
      </div>
      </>
    )
  }
}

export default Home;