import React from 'react';
import './style.css';
import axios from 'axios';
import { store } from '../../store/index';
import { useState, useEffect } from 'react';
import Button from '../Button';
import { isDisabled } from '@testing-library/user-event/dist/utils';
import { set } from 'date-fns';

function AreaRiservata() {

    const [email, setEmail] = useState('');
     useEffect(() => {
         setEmail(localStorage.getItem('email'));
     }, []);
   

    const recuperoPassword = async () => {
        window.loading(true);

        try {
            const result = await axios.post(window.apiUrl + '/resPsw', formDataCoupon);
            // La chiamata è andata a buon fine, puoi gestire la risposta qui
            console.log(result.data);
            if ('message' in result.data) {
                window.loading(false);
                window.openModal1("Recupero password", result.data.message);
            }

        } catch (error) {
            window.loading(false);
            // Gestisci l'errore in modo appropriato
            // estraggo il response
            let message = 'Errore generico recupero password';
            if (error.response) {
                message = error.response.data.message;
            }

            console.error('Error:', message);
            window.openModal1("Errore recupero password", message)
        } finally {
            window.loading(false);
        }

    } 

    const cambioPassword = async () => {
        window.loading(true);
        const bearer_token = `Bearer ${store.getState().savedToken}`;
        console.log("email", localStorage.getItem('email'));
        const config = {
            headers: {
                Authorization: bearer_token
            }
        };

        if (!localStorage.getItem('email')){
            window.loading(false);
            window.openModal1("Errore cambio password", "Email non presente");
            return;
        }

        try {
            const result = await axios.post(window.apiUrl + '/changePsw', formData, config);
            // La chiamata è andata a buon fine, puoi gestire la risposta qui
            console.log(result.data);
            if ('message' in result.data) {
                window.loading(false);
                window.openModal1("Cambio password", result.data.message);
            }

        } catch (error) {
            window.loading(false);
            // Gestisci l'errore in modo appropriato
            // estraggo il response
            let message = 'Errore generico cambio password';
            if (error.response) {
                message = error.response.data.message;
            }

            console.error('Error:', message);
            window.openModal1("Errore cambio password", message)
        } finally {
            window.loading(false);
            console.log("finally, loading false");
        }
    };

    /*  GESTISCO IL FORM  PER IL RECUPERO DELLA PASSWORD  */
    const [formDataCoupon, setFormDataCoupon] = useState({
        coupon: '',
    });
    const handleInputChangeCoupon = (event) => {
        setFormDataCoupon({ ...formData, [event.target.name]: event.target.value });
    };

    /*  GESTISCO IL FORM    */
    const [formData, setFormData] = useState({
        email: localStorage.getItem('email'),
        old_psw: '',
        new_psw: '',
    });

    const handleInputChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    };


    return (
        <div className="homeContainer">
  
            <h1 className='titoloCentrale'>Area Riservata</h1>
            {store.getState().savedToken ?
            <div className="textBox homeChoise">
                <form onSubmit={handleSubmit}>
                    <div className="container mt-4">
                        <div className="row">
                            <div className="col-md formCoupon">

                                <h2>Cambio della password</h2>
                                <label>Vecchia Password: </label><input type="password" name="old_psw" value={formData.old_psw} onChange={handleInputChange} />
                                <br />
                                <label>Nuova Password: </label><input type="password" name="new_psw" value={formData.new_psw} onChange={handleInputChange} />
                                <br />
                                <button onClick={cambioPassword}>Cambia Password</button>

                                    <br /><br />
                                    <p>Email collegata: {email}</p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            :
            <div className="textBox homeChoise">
                <p>Per recuperare la password inserire un codice coupon utilizzato</p>
                <form onSubmit={handleSubmit}>
                    <div className="container mt-4">
                        <div className="row">
                            <div className="col-md formCoupon">
                                    <label>Coupon </label><input type="text" name="coupon" value={formData.coupon} onChange={handleInputChangeCoupon} />
                                    <br />
                                    <button onClick={recuperoPassword}>Recupero Password</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>}
            <div className="appInfo">
                <p>Versione 1.20</p>
            </div>
        </div>
    )

}

export default AreaRiservata;