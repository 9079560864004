import React from "react";
import axios from "axios";
import { store } from "../../store/index";
import { useState, useEffect } from "react";

export function RitiraAttestato  (probs) {
        
        
    console.log("Ritira attestato");

    const [attestato, setAttestato] = useState("");


    const DownloadAttestato = () => {
        const accessToken = store.getState().savedToken;
        const headers = { 'Authorization': `Bearer ${accessToken}` };
       
        axios.get(window.apiUrl + "/attestato?id_team="+probs.id, { headers }, { responseType: 'blob' })
            .then(
                response => {
                    setAttestato(response.data.data.attestato);
                    console.log(response.data.data.attestato);
                }
            );
    };

    return (
        <div>
            <button onClick={DownloadAttestato}>Ritira attestato</button>
            {attestato !== "" && <>
            <button onClick={() => (window.open("https://docs.google.com/forms/d/e/1FAIpQLSeER-EhGGtC0DqtD3mYMNcgWXn5WUJeBdgtDR0r4YB95VEmHw/viewform", '_blank').focus())}>Compila il questionario</button>
            <button onClick={() => (window.open(window.attestatiUrl+"/"+attestato))}>Scarica</button>
            </>}
            
        </div>
    );
}