import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import couponApi from "./apis/couponApi";


export const store = configureStore({
    reducer: {
        coupons: couponApi.reducer,
        savedToken: (state = null, action) => {    
            switch (action.type) {
                case 'SET_TOKEN':
                    return action.payload
                default:
                    return state
            }
        },
        user: (state = null, action) => {
            switch (action.type) {
                case 'SET_USER':
                    return action.payload
                default:
                    return state
            }
        },
        email: (state = null, action) => {
            switch (action.type) {
                case 'SET_EMAIL':
                    return action.payload
                default:
                    return state
            }
        },

    },
    middleware: (getDefaultMiddleware) => {
       return getDefaultMiddleware().concat(couponApi.middleware)
    },

});

setupListeners(store.dispatch);

export { useGetCouponQuery, useCouponRedentionMutation } from "./apis/couponApi";
